<template>
  <sdModal
    type="primary"
    :width="700"
    :visible="openModal"
    :onCancel="handleCloseModal"
  >
    <a-row :gutter="25"  style="text-align: left;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <p class="modal-title">
          ¿Estás seguro de darle permisos de edición al usuario que se registró a este evento?
        </p>
      </a-col>
    </a-row>
    <a-row v-if="loading">
      <a-col 
        :xxl="24" 
        :lg="24" 
        :md="24"
        :xs="24"
      >
          <sdCards>
              <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
          </sdCards>
      </a-col>
    </a-row>
    <a-row :gutter="25" justify="center" style="text-align: center; margin-top: 2rem;" v-else>

        <button class="btn-outlined-exit mt-2 pointer">
          <span class="title-normal" v-on:click="handleCloseModal"> Cancelar </span>
        </button>


        <button class="btn-fill-add mt-2 pointer">
          <span class="title-normal" v-on:click="handleSubmit"> Aceptar </span>
        </button>

    </a-row>
  </sdModal>
</template>
<script>


export default {
  name: 'RegistrationUserToEvent',
  components: {
  },
  props: [
    'openModal',
    'loading'
  ],
  emits: ['closeModal', 'submit'],
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleSubmit() {
      this.$emit('submit');
    },
  }
};

</script>

<style scoped lang='sass'>
.modal-title
  font-size: 14px
  color: #7b868c

button
  padding: 5px 15px 5px 15px
  border-radius: 24px

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  background: #3FA7E1

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  background: none

.pointer
  cursor: pointer
</style>
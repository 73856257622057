<template>
  <div>
    <Main style='margin-top: 2rem;'>
      <div v-if='questions'  class='form-answers'>
        <a-row>
          <a-col :xxl='24' :lg='24' :xs='24'>
            <sdCards class='form-answers__header'>
              <a-row :gutter='25' class='title'>{{questions.sections[0].title}}</a-row>
              <a-row :gutter='25' class='subtitle'>{{questions.sections[0].description}}</a-row>
            </sdCards>
          </a-col>
        </a-row>
        <a-row :gutter='25' v-for='section in questions.sections' :key='section.id'>
          <a-col :xxl='24' :lg='24' :xs='24' v-for='question in section.questions' :key='question.id'>
            <sdCards class='question-card'>
              <a-form
                name='sDash_validation-form'
                ref='formRef'
                layout='vertical'
              >
                <question-render
                  :modelValue='answers[question.id].content'
                  :question='question'
                  :read-only='true'
                  @update:modelValue='answers[question.id].content = $event'

                />
              </a-form>

            </sdCards>

          </a-col>
          <a-col :xs='24' class="text-center" v-if="roles.RESPONSABLE_DE_EVENTO">
            <button @click="handleOpenModal" class="btn">Habilitar edición</button>
          </a-col>
        </a-row>
        <confirm-registration-modal
          :openModal="showModal"
          :loading="loadingSubmit"
          v-on:closeModal="handleCloseModal"
          v-on:submit="handleSubmit"
        />
      </div>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { getDynamicFormAnswers, allowEditFormAnswers } from '@/services/events';
import QuestionRender from '@/view/home/overview/QuestionRender';
import ConfirmRegistrationModal from './overview/answers/ConfirmRegistrationModal.vue';
import { Notification } from 'ant-design-vue';
import { getItem } from '@/utility/localStorageControl';
// import { defineAsyncComponent } from 'vue';

export default {
  name: 'Answers',
  components: {
    Main,
    QuestionRender,
    ConfirmRegistrationModal
  },
  data: () => {
    return {
      answers: null,
      questions: null,
      event_id: null,
      assistant_id: null,
      showModal: false,
      loadingSubmit: false,
      roles: [],
      router: useRouter(),
      match: useRoute(),
    };
  },
  methods: {
    getRolesPermition () {
      const roles = getItem('roles');
      let rolesPermitions = {
        DIRECTOR_DE_CCT: false,
        ADMINISTRADOR_GENERAL: false,
        ADMINISTRADOR_DE_AREA: false,
        PARTICIPANTE: false,
        RESPONSABLE_DE_EVENTO: false,
      }
      if(roles != undefined){
        roles.forEach(rol => {
          if(rol.permiso == "activo"){
            switch(rol.nombre){
              case "Director de CCT":
                rolesPermitions.DIRECTOR_DE_CCT = true;
              break;
              case "Administrador general":
                rolesPermitions.ADMINISTRADOR_GENERAL = true;
              break;
              case "Administrador de area":
                rolesPermitions.ADMINISTRADOR_DE_AREA = true;
              break;
              case "Participante":
                rolesPermitions.PARTICIPANTE = true;
              break;
              case "Responsable de evento":
                rolesPermitions.RESPONSABLE_DE_EVENTO = true;
              break;
            }
          }
        });
      }
      return rolesPermitions;
    },
    getAnswers: async function() {
      console.log(this.match.params.id);
      const response = await getDynamicFormAnswers(this.match.params.id);
      if (response.success) {
        this.answers = response.data.answers_json;
        this.questions = response.data.questions_json;
        this.assistant_id = response.data.assistant.user_id;
        this.event_id = response.data.event_id;
        console.log(this.questions);
        console.log(this.answers);
      }
    },
    handleOpenModal() {
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    async handleSubmit() {
      this.loadingSubmit = true;
      const form = new FormData();
      form.set('usersId[0]', this.assistant_id);
      const response = await allowEditFormAnswers(this.event_id, form);
      if (response.success) {
        Notification['success']({message:'Éxito', description: response.message});
        setTimeout(() => {
          this.router.go(-1);
        }, 2000);
      } else {
        Notification['error']({message:'Error', description: response.message || 'Oops ocurrió un error inesperado'});
      }
    },
  },
  mounted() {
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', 'Ver respuestas de registro');
    this.getAnswers();
    this.roles = this.getRolesPermition();
  },
};
</script>
<style scoped lang='sass'>
.form-answers
  .form-answers__header
    padding-left: 21px
    .title
      color: #404040
      font-family: 'Nutmeg Bold'
      font-size: 18px
      margin-bottom: 18px
    .subtitle
      color: #7B868C
      font-family: 'Avenir Roman'
      font-size: 14px

button
  padding: 5px 15px 5px 15px
  border-radius: 24px

button:hover
  cursor: pointer

.btn
  border: 1px solid #3FA7E1
  background: #3FA7E1
  color: white
</style>
